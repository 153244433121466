<template>
  <v-container
    fluid
    tag="section"
  >
    <validation-observer v-slot="{ valid }">
      <material-wizard
        title="Importar BDGD"
        subtitle="Preencha o formulário e faça o upload dos arquivos da nova BDGD."
        v-model="tab"
        :available-steps="availableSteps"
        :tabs="tabs"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="tab--"
        :loading="loading"
        :disabledBtnFinalizar="disabledBtnFinalizar"
      >
        <v-tab-item class="pb-1">
          <form @submit.prevent="next(valid)">
            <first-tab-content @import:updated="handleTabContentUpdated" />
          </form>
        </v-tab-item>
        <v-tab-item class="pb-1">
          <second-tab-content
            :formatoGdbAneel="importacao.formatoGdbAneel"
            :modulo10_antigo="importacao.modulo10_antigo"
            @import:updated="handleTabContentUpdated"
            @mapeamento:atualizado="handleTabContentUpdated"
            @mapeamento:apagar-arquivo="handleTabContentArquivoExcluido"
          />
        </v-tab-item>
      </material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
import MaterialWizard from '@/components/base/MaterialWizard.vue';
import FirstTabContent from '@/components/geracao-bdgd/importacoes-bdgd/tabs/FirstTabContent.vue';
import SecondTabContent from '@/components/geracao-bdgd/importacoes-bdgd/tabs/SecondTabContent.vue';
import ImportacoesBdgdService from '@/services/ImportacoesBdgdService';
import routes from '@/store/modules/routes';

export default {
  components: {
    MaterialWizard,
    FirstTabContent,
    SecondTabContent
  },
  data: () => ({
    tab: 0,
    tabs: ['Informações básicas', 'Upload de arquivos'],
    loading: false,
    importacao: {
      titulo: null,
      descricao: null,
      companyId: null,
      data: null,
      mes: null,
      bdgdVersion: null,
      formatoGdbAneel: false,
      modulo10_antigo: false,
      encoding: 'UTF-8',
      mapeamentos: {},
      numeroArquivos: 0,
      numeroArquivosDados: 0,
      numeroMapeados: 0,
      numeroNaoMapeados: 0
    }
  }),
  computed: {
    availableSteps() {
      let steps = [];

      if (this.tab1IsValid) {
        steps.push(0);
        steps.push(1);
      }

      if (this.tab2IsValid) {
        steps.push(2);
      }

      return steps;
    },
    tab1IsValid() {
      const { titulo, companyId, mes, bdgdVersion } = this.importacao;
      return (
        titulo !== null &&
        companyId !== null &&
        mes !== null &&
        bdgdVersion !== null
      );
    },
    tab2IsValid() {
      const {
        numeroArquivos,
        numeroMapeados,
        numeroNaoMapeados,
        formatoGdbAneel
      } = this.importacao;
      return (
        this.tab1IsValid &&
        ((numeroArquivos > 0 &&
          numeroMapeados > 0 &&
          numeroArquivos === numeroMapeados &&
          numeroNaoMapeados === 0) ||
          formatoGdbAneel)
      );
    },
    disabledBtnFinalizar() {
      if (this.tab === 0) return !this.tab1IsValid;
      if (this.tab === 1) return !this.tab2IsValid;
      return true;
    }
  },
  methods: {
    async next(valid) {
      if (!valid) return;

      if (this.tab === 0 && !this.tab1IsValid) {
        return;
      }

      if (this.tab === 1) {
        if (!this.tab2IsValid) {
          return;
        }

        const sucesso = await this.cadastraNovaImportacao();
        if (!sucesso) {
          return;
        }

        this.$router.push({
          name: routes.state.appRoutes['IMPORTACOES_DE_BDGD']
        });
      } else {
        this.tab++;
      }
    },
    handleTabContentUpdated(event) {
      this.importacao = Object.assign(this.importacao, event);
    },
    handleTabContentArquivoExcluido(event) {
      let importacao = this.importacao;

      if (importacao.numeroArquivos > 0) importacao.numeroArquivos -= 1;

      /* Verifica se o arquivo a ser excluido foi mapeado */
      if (event.id in importacao.mapeamentos) {
        delete importacao.mapeamentos[event.id];
        if (importacao.numeroMapeados > 0) importacao.numeroMapeados -= 1;
      } else {
        if (importacao.numeroNaoMapeados > 0) importacao.numeroNaoMapeados -= 1;
      }

      this.importacao = importacao;
    },
    async cadastraNovaImportacao() {
      let sucesso = false;

      if (
        this.importacao.numeroArquivosDados === 0 &&
        !this.importacao.formatoGdbAneel
      ) {
        this.$toast.error(
          'Por favor, envio ao menos 1 arquivo SHP ou DBF.',
          '',
          { position: 'topRight' }
        );
        return sucesso;
      }

      this.loading = true;

      try {
        sucesso = await ImportacoesBdgdService.save(this.importacao);
        this.$toast.success('Importação de BDGD cadastrada com sucesso.', '', {
          position: 'topRight'
        });
      } catch (e) {
        this.$toast.error(
          'Ocorreu um erro ao cadastrar a importação de BDGD.',
          '',
          { position: 'topRight' }
        );
        console.log(e);
      } finally {
        this.loading = false;
      }

      return sucesso;
    }
  }
};
</script>
