<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Por favor, envie os arquivos que compo&ecirc;m a BDGD
    </div>
    <v-row
      id="importacao-encoding"
      class="mx-auto"
    >
      <v-col cols="12">
        <select-encoding
          id="fs-encoding-importacao-bdgd"
          label="Codificação dos arquivos"
          type="outlined"
          @encoding:selected="encodingSelected"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-upload-files"
      class="mx-auto"
    >
      <v-col
        cols="12"
        class="container-upload"
      >
        <upload-files
          :formatoGdbAneel="formatoGdbAneel"
          :modulo10_antigo="modulo10_antigo"
          id="fs-upload-importacao-bdgd"
          :allowedFiles="allowedFiles"
          @file:uploaded="handleFileUploaded"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-uploaded-files-table"
      class="mx-auto"
      v-if="!formatoGdbAneel"
    >
      <v-col cols="12">
        <tabela-mapeamento-arquivos
          id="fs-tabela-importacao-bdgd"
          :modulo10_antigo="modulo10_antigo"
          :arquivos="uploadedFiles"
          :extensoes-permitidas="allowedFiles"
          @mapeamento:atualizado="handleMapeamentoAtualizado"
          @mapeamento:apagar-arquivo="handleMapeamentoArquivoApagado"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="formatoGdbAneel && lastUploadedFile"
    >
      <v-col cols="12">
        <v-text-field
          label="Arquivo enviado"
          outlined
          readonly
          v-model="lastUploadedFile.arquivo"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ImportacoesBdgdService from '@/services/ImportacoesBdgdService';
import SelectEncoding from '@/components/general/SelectEncoding.vue';
import UploadFiles from '@/components/general/UploadFiles/Index.vue';
import TabelaMapeamentoArquivos from '@/components/geracao-bdgd/importacoes-bdgd/TabelaMapeamentoArquivos.vue';

export default {
  name: 'SecondTabContent',
  components: {
    SelectEncoding,
    UploadFiles,
    TabelaMapeamentoArquivos
  },
  props: {
    formatoGdbAneel: {
      type: Boolean,
      default: false
    },
    modulo10_antigo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      encoding: 'UTF-8',
      allowedFiles: [],
      uploadedFiles: []
    };
  },
  computed: {
    lastUploadedFile() {
      return this.uploadedFiles[this.uploadedFiles.length - 1];
    }
  },
  watch: {
    encoding() {
      this.emitEventImportUpdated();
    },
    lastUploadedFile() {
      this.emitEventImportUpdated();
    }
  },
  mounted() {
    this.getAllowedFiles();
  },
  methods: {
    emitEventImportUpdated() {
      this.$emit('import:updated', {
        encoding: this.encoding,
        lastUploadedFile: this.lastUploadedFile
      });
    },
    encodingSelected(event) {
      this.encoding = event;
    },
    getAllowedFiles() {
      ImportacoesBdgdService.getAllowedFiles()
        .then((response) => (this.allowedFiles = response.data))
        .catch((error) => console.log('Error: ' + error));
    },
    handleFileUploaded(event) {
      const { files } = event;
      files.forEach((file) => this.uploadedFiles.push(file));
    },
    handleMapeamentoAtualizado(event) {
      this.$emit('mapeamento:atualizado', event);
    },
    handleMapeamentoArquivoApagado(event) {
      this.uploadedFiles = this.uploadedFiles.filter(
        (item) => item.id !== event.id
      );
      this.$emit('mapeamento:apagar-arquivo', event);
    }
  }
};
</script>

<style>
.container-upload {
  padding-top: 0;
}
</style>
