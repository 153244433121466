<template>
  <div v-show="numeroArquivos">
    <div class="text-center display-1 font-weight-normal mb-6">
      {{ numeroArquivos }} arquivos enviados, {{ numeroMapeados }} mapeados e
      {{ numeroNaoMapeados }} não mapeados
    </div>
    <v-alert
      v-show="numeroEntidadesAusentes"
      color="orange"
      outlined
      type="warning"
    >
      <span>{{ msgEntidadesAusentes }} Deseja continuar mesmo assim?</span>
    </v-alert>
    <v-text-field
      id="search"
      v-model="search"
      append-icon="mdi-magnify"
      class="ml-auto mb-8 mt-4 break-search"
      label="Procurar"
      hide-details
      single-line
    />
    <v-data-table
      class="importacoes-bdgd-table"
      :headers="[
        {
          text: 'Arquivo',
          value: 'arquivo'
        },
        {
          sortable: false,
          text: 'Entidade',
          value: 'entidade'
        },
        {
          sortable: false,
          text: 'Ações',
          value: 'actions',
          align: 'center'
        }
      ]"
      :items="arquivos"
      :search.sync="search"
      :headerProps="{
        sortByText: 'Ordenar Por'
      }"
      disable-pagination
      hide-default-footer
      dense
      no-data-text="Nenhum arquivo encontrado"
      no-results-text="Nenhum registro correspondente encontrado"
      :mobile-breakpoint="800"
    >
      <template v-slot:[`item.entidade`]="{ item }">
        <mapeamento-arquivo
          :entidades="entidades"
          :arquivo="item"
          @mapeamento:atualizado="handleMapeamentoAtualizado"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="gray"
              min-width="0"
              class="px-1"
              fab
              icon
              x-small
              v-on="on"
              v-bind="attrs"
              @click="
                $emit('mapeamento:apagar-arquivo', {
                  nome: item.arquivo,
                  id: item.id
                })
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Clique aqui para excluir o arquivo {{ item.arquivo }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ImportacoesBdgdService from '@/services/ImportacoesBdgdService';

export default {
  components: {
    MapeamentoArquivo: () =>
      import('@/components/geracao-bdgd/importacoes-bdgd/MapeamentoArquivo.vue')
  },
  props: {
    arquivos: {
      type: Array,
      required: true,
      default: () => []
    },
    extensoesPermitidas: {
      type: Array,
      required: true,
      default: () => []
    },
    modulo10_antigo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      entidades: [],
      mapeamentos: {},
      search: ''
    };
  },
  computed: {
    arquivosRelevantes() {
      return this.arquivos
        .filter((arquivo) =>
          this.extensoesPermitidas.includes(arquivo.extensao.toLowerCase())
        )
        .sort((a, b) => {
          if (a.arquivo > b.arquivo) {
            return 1;
          }
          if (a.arquivo < b.arquivo) {
            return -1;
          }
          return 0;
        });
    },
    numeroArquivos() {
      return this.arquivosRelevantes.length;
    },
    numeroArquivosDados() {
      return this.arquivosRelevantes.reduce((accumulator, currentValue) => {
        let extensao = currentValue.extensao.toLowerCase();
        return (accumulator += extensao == 'dbf' || extensao == 'shp' ? 1 : 0);
      }, 0);
    },
    entidadesMapeadas() {
      const entidades = Object.values(this.mapeamentos);
      const entidadesUnicas = [...new Set(entidades)];
      return entidadesUnicas.filter((entidade) => entidade !== null);
    },
    entidadesAusentes() {
      return this.entidades.filter(
        (entidade) => !this.entidadesMapeadas.includes(entidade)
      );
    },
    numeroEntidadesAusentes() {
      return this.entidadesAusentes.length;
    },
    numeroMapeados() {
      const entidades = Object.values(this.mapeamentos);
      return entidades.filter((entidade) => entidade !== null).length;
    },
    numeroNaoMapeados() {
      const entidades = Object.values(this.mapeamentos);
      return entidades.filter((entidade) => entidade === null).length;
    },
    msgEntidadesAusentes() {
      let msg = '';
      if (this.numeroEntidadesAusentes === 1) {
        msg = 'Existe uma entidade ausente em seu mapeamento: ';
      } else if (this.numeroEntidadesAusentes > 1) {
        msg = `Existem ${this.numeroEntidadesAusentes} entidades ausentes em seu mapeamento: `;
      }
      return msg === '' ? '' : msg + this.entidadesAusentes.join(', ') + '.';
    }
  },
  mounted() {
    this.getEntidades();
  },
  methods: {
    getEntidades() {
      ImportacoesBdgdService.getEntidades({
        modulo10_antigo: this.modulo10_antigo
      })
        .then((response) => (this.entidades = response.data))
        .catch((error) => console.log('Error: ' + error));
    },
    handleMapeamentoAtualizado($event) {
      const { arquivoId, entidade } = $event;
      this.$set(this.mapeamentos, arquivoId, entidade);
      this.emiteEventoMapeamentoAtualizado();
    },
    emiteEventoMapeamentoAtualizado() {
      this.$emit('mapeamento:atualizado', {
        mapeamentos: this.mapeamentos,
        numeroArquivos: this.numeroArquivos,
        numeroArquivosDados: this.numeroArquivosDados,
        numeroMapeados: this.numeroMapeados,
        numeroNaoMapeados: this.numeroNaoMapeados
      });
    }
  }
};
</script>
