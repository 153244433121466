var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.numeroArquivos),expression:"numeroArquivos"}]},[_c('div',{staticClass:"text-center display-1 font-weight-normal mb-6"},[_vm._v(" "+_vm._s(_vm.numeroArquivos)+" arquivos enviados, "+_vm._s(_vm.numeroMapeados)+" mapeados e "+_vm._s(_vm.numeroNaoMapeados)+" não mapeados ")]),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.numeroEntidadesAusentes),expression:"numeroEntidadesAusentes"}],attrs:{"color":"orange","outlined":"","type":"warning"}},[_c('span',[_vm._v(_vm._s(_vm.msgEntidadesAusentes)+" Deseja continuar mesmo assim?")])]),_c('v-text-field',{staticClass:"ml-auto mb-8 mt-4 break-search",attrs:{"id":"search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"importacoes-bdgd-table",attrs:{"headers":[
      {
        text: 'Arquivo',
        value: 'arquivo'
      },
      {
        sortable: false,
        text: 'Entidade',
        value: 'entidade'
      },
      {
        sortable: false,
        text: 'Ações',
        value: 'actions',
        align: 'center'
      }
    ],"items":_vm.arquivos,"search":_vm.search,"headerProps":{
      sortByText: 'Ordenar Por'
    },"disable-pagination":"","hide-default-footer":"","dense":"","no-data-text":"Nenhum arquivo encontrado","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":800},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.entidade",fn:function(ref){
    var item = ref.item;
return [_c('mapeamento-arquivo',{attrs:{"entidades":_vm.entidades,"arquivo":item},on:{"mapeamento:atualizado":_vm.handleMapeamentoAtualizado}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-1",attrs:{"color":"gray","min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.$emit('mapeamento:apagar-arquivo', {
                nome: item.arquivo,
                id: item.id
              })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Clique aqui para excluir o arquivo "+_vm._s(item.arquivo))])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }